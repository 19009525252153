import { MerchantDashboard } from './dashboard';
import { MerchantPayment } from './payment';
import { MerchantOther } from './other';

// ==============================|| MENU ITEMS ||============================== //

const DefaultMenuItems = {
    items: [MerchantDashboard, MerchantPayment, MerchantOther]
};

export const MerchantMenuItems = {
    items: [MerchantDashboard, MerchantPayment, MerchantOther]
};

export default DefaultMenuItems;
