import { memo, useEffect, useContext } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Typography, useMediaQuery } from '@mui/material';

// project imports
import { MerchantMenuItems } from 'menu-items';
import NavGroup from './NavGroup';
import useConfig from 'hooks/useConfig';
import { HORIZONTAL_MAX_ITEM } from 'config';
import LAYOUT_CONST from '../../../constant';
import useAuth from 'hooks/useAuth';
import { IconUpload, IconNewSection } from '@tabler/icons';
import { FormattedMessage } from 'react-intl';

const MenuList = () => {
    const theme = useTheme();
    const { user } = useAuth();

    const { layout } = useConfig();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
    const menuItem = user?.isFileUploadEnable
        ? [
              ...MerchantMenuItems.items,
              {
                  id: 'file-upload',
                  title: <FormattedMessage id="Upload" />,
                  icon: IconUpload,
                  type: 'group',
                  children: [
                      {
                          id: 'file-upload',
                          title: <FormattedMessage id="File" />,
                          type: 'item',
                          url: '/file-upload',
                          icon: IconUpload,
                          breadcrumbs: false
                      }
                  ]
              },
              {
                  id: 'create-payout',
                  title: <FormattedMessage id="Create" />,
                  icon: IconNewSection,
                  type: 'group',
                  children: [
                      {
                          id: 'create-payout',
                          title: <FormattedMessage id="Create Payout" />,
                          type: 'item',
                          url: '/create-payout',
                          icon: IconNewSection,
                          breadcrumbs: false
                      }
                  ]
              }
          ]
        : MerchantMenuItems?.items;

    const indexToAddChild = 1;

    const newChildObject = {
        id: 'clear-payin',
        title: <FormattedMessage id="Clear Payin" />,
        type: 'item',
        url: '/payment/clear-payin',
        icon: IconNewSection,
        breadcrumbs: false
    };

    if (user?.isAutoEnable && !menuItem[indexToAddChild].children.find((child) => child.id === newChildObject.id)) {
        menuItem[indexToAddChild].children.push(newChildObject);
    }

    console.log(menuItem);
    const handlerMenuItem = () => {
        const isFound = menuItem.some((element) => {
            if (element.id === 'widget') {
                return true;
            }
            return false;
        });

        // if (getMenu?.id !== undefined && !isFound) {
        //     menuItem.splice(3, 0, getMenu);
        // }
    };

    console.log(user);

    useEffect(() => {
        handlerMenuItem();
        // eslint-disable-next-line
    }, []);

    // last menu-item to show in horizontal menu bar
    const lastItem = layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && !matchDownMd ? HORIZONTAL_MAX_ITEM : null;

    let lastItemIndex = menuItem.length - 1;
    let remItems = [];
    let lastItemId;

    if (lastItem && lastItem < menuItem.length) {
        lastItemId = menuItem[lastItem - 1].id;
        lastItemIndex = lastItem - 1;
        remItems = menuItem.slice(lastItem - 1, menuItem.length).map((item) => ({
            title: item.title,
            elements: item.children
        }));
    }

    const navItems = menuItem.slice(0, lastItemIndex + 1).map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} lastItem={lastItem} remItems={remItems} lastItemId={lastItemId} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return <>{navItems}</>;
};

export default memo(MenuList);
