import React, { useEffect } from 'react';

// material-ui
import { Table, TableRow, TableBody, TableCell, TableContainer, TablePagination, CardContent, Grid, TextField, Chip } from '@mui/material';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import { getFileReports } from 'utils/api';
import { Download, RefreshOutlined } from '@mui/icons-material';
import TableHeader from 'ui-component/TableHead';
import Loader from 'ui-component/Loader';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ISTDate } from 'constant';

// table header options
const headCells = [
    {
        id: 'date',
        numeric: true,
        label: 'Date',
        align: 'left'
    },
    {
        id: 'fileName',
        numeric: false,
        label: 'File Name',
        align: 'left'
    },
    {
        id: 'status',
        numeric: true,
        label: 'Status',
        align: 'left'
    }
];

const currentDate = ISTDate;

const Reports = () => {
    const [rows, setRows] = React.useState([]);
    const [data, setData] = React.useState();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(50);
    const [loading, setLoading] = React.useState(false);
    const [startDate, setStartDate] = React.useState(currentDate);
    const [endDate, setEndDate] = React.useState(currentDate);

    const fetchDataFromAPI = async (page, rowsPerPage, startDate, endDate) => {
        setLoading(true);
        try {
            const result = await getFileReports(page, rowsPerPage, startDate, endDate);
            setLoading(false);
            setRows(result?.data?.content || []);
            setData(result?.data);
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchDataFromAPI(page, rowsPerPage, startDate, endDate);
    }, [page, rowsPerPage, startDate, endDate]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event?.target.value, 10));
        setPage(0);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    return (
        <MainCard title="Uploade Files">
            {loading && <Loader />}
            <CardContent sx={{ paddingBottom: '10px 0px 10px 0px' }}>
                <Grid container style={{ display: 'flex', justifyContent: 'space-between' }} alignItems="center" spacing={2}>
                    <Grid
                        container
                        style={{ display: 'flex', justifyContent: 'start', flexWrap: 'wrap' }}
                        item
                        xs={12}
                        sm={12}
                        alignItems="baseline"
                        spacing={2}
                    >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                inputFormat="YYYY/MM/DD"
                                value={startDate}
                                onChange={(value) => {
                                    const dt = new Date(value);
                                    const stDate = dayjs(dt).format('YYYY-MM-DD');
                                    setStartDate(stDate);
                                }}
                                minDate={new Date(new Date().setDate(new Date().getDate() - 30))}
                                maxDate={ISTDate}
                                renderInput={(params) => <TextField sx={{ width: 210, mr: 2 }} size="small" {...params} />}
                                label="Start Date"
                            />
                            <DatePicker
                                inputFormat="YYYY/MM/DD"
                                value={endDate}
                                minDate={startDate}
                                maxDate={ISTDate}
                                onChange={(value) => {
                                    const dt = new Date(value);
                                    const enDate = dayjs(dt).format('YYYY-MM-DD');
                                    setEndDate(enDate);
                                }}
                                renderInput={(params) => <TextField sx={{ width: 210, mr: 2, ml: 2 }} size="small" {...params} />}
                                label="End Date"
                            />
                        </LocalizationProvider>
                    </Grid>
                </Grid>
            </CardContent>
            <TableContainer>
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                    <TableHeader columns={headCells} />

                    <TableBody>
                        {rows.map((row, index) => {
                            /** Make sure no display bugs if row isn't an OrderData object */
                            if (typeof row === 'number') return null;
                            const labelId = `enhanced-table-checkbox-${index}`;

                            return (
                                <TableRow hover>
                                    <TableCell style={{ fontWeight: 500 }} component="th" id={labelId} scope="row">
                                        <div style={{ width: 220 }}>
                                            Created: {row.createdAt ? new Date(row.createdAt).toLocaleString() : ''}
                                        </div>
                                        <div>Updated: {row.updatedAt ? new Date(row.updatedAt).toLocaleString() : ''}</div>
                                    </TableCell>
                                    <TableCell>{row.fileName}</TableCell>
                                    <TableCell>
                                        <Chip size="small" color="success" style={{ fontSize: 10, color: 'white' }} label={row.status} />
                                    </TableCell>
                                    <TableCell>
                                        {row.status === 'Processed' && (
                                            <a href={row.downloadLink} target="__blank">
                                                <Download style={{ marginTop: 10, fill: 'green', fontSize: 18, cursor: 'pointer' }} />
                                            </a>
                                        )}
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                        {emptyRows > 0 && (
                            <TableRow
                                style={{
                                    height: 53 * emptyRows
                                }}
                            >
                                <TableCell colSpan={6} />
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* table pagination */}
            <TablePagination
                rowsPerPageOptions={[50, 100, 200]}
                component="div"
                count={data?.totalSize}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </MainCard>
    );
};

export default Reports;
