import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { closeSnackbar, openSnackbar } from 'store/slices/snackbar';
import Loader from 'ui-component/Loader';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import AnimateButton from 'ui-component/extended/AnimateButton';
import axiosServices from 'utils/axios';
import Reports from './Report';

const Upload = () => {
    const dispatch = useDispatch();
    const [file, setFile] = useState();
    const [loading, setLoading] = useState(false);

    const onLoad = (fileString) => {
        setFile(fileString);
    };

    const handleUploadFile = (e) => {
        const file = e.target.files[0];
        onLoad(file);
        // const reader = new FileReader();
        // reader.readAsDataURL(file);
        // reader.onload = () => {
        //     onLoad(reader.result);
        // };
    };

    const uploadFile = () => {
        if (file) {
            setLoading(true);
            const formData = new FormData();
            formData.append('file', file);
            axiosServices
                .post(`uploadAndProcessPayoutFile`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then((res) => {
                    setLoading(false);
                    dispatch(
                        openSnackbar({
                            open: true,
                            message: res.data || 'success',
                            variant: 'alert',
                            alert: {
                                color: 'success'
                            },
                            close: false
                        })
                    );
                })
                .catch((e) => {
                    setLoading(false);
                    dispatch(
                        openSnackbar({
                            open: true,
                            message: e?.message || 'Error while uploading',
                            variant: 'alert',
                            alert: {
                                color: 'error'
                            },
                            close: true
                        })
                    );
                    dispatch(closeSnackbar);
                });
        } else {
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Select file',
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    close: true
                })
            );
        }
    };

    const handleDownlaod = () => {
        axiosServices
            .get('getPayoutSampleFile')
            .then((r) => {
                console.log(r);
                window.location.href = r.data?.data;
            })
            .catch((e) => {
                setLoading(false);
                dispatch(
                    openSnackbar({
                        open: true,
                        message: e?.message || 'Error while uploading',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        },
                        close: true
                    })
                );
                dispatch(closeSnackbar);
            });
    };

    return (
        <>
            <MainCard title="Upload">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <input type="file" onChange={handleUploadFile} />
                    <AnimateButton>
                        <Button onClick={uploadFile} variant="contained">
                            Upload
                        </Button>
                    </AnimateButton>

                    <div
                        role="button"
                        tabIndex={0}
                        onClick={handleDownlaod}
                        onKeyDown={handleDownlaod}
                        style={{ cursor: 'pointer', color: 'blue', marginLeft: 20 }}
                    >
                        Download Sample File
                    </div>
                </div>
                {loading && <Loader />}
            </MainCard>
            <Reports />
        </>
    );
};

export default Upload;
