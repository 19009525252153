import PropTypes from 'prop-types';
import { forwardRef, useEffect, useState } from 'react';

// material-ui
import {
    Button,
    Switch,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    Slide,
    TextField,
    MenuItem,
    Autocomplete
} from '@mui/material';

import AnimateButton from 'ui-component/extended/AnimateButton';
import { useFormik } from 'formik';
import * as yup from 'yup';
import axiosServices from 'utils/axios';
import Loader from 'ui-component/Loader';
import useAuth from 'hooks/useAuth';
import { useDispatch } from 'react-redux';
import { openSnackbar } from 'store/slices/snackbar';

// animation
const Transition = forwardRef((props, ref) => <Slide direction="left" ref={ref} {...props} />);

// ==============================|| Merchant ADD DIALOG ||============================== //

const Add = ({ open, handleCloseDialog }) => {
    const { user } = useAuth();
    const dispatch = useDispatch();
    const [details, setDetails] = useState({
        // account_holder: 0,
        // account_number: 0,
        // ifsc_code: 35
        customer_id: '1'
    });

    const [loading, setLoading] = useState(false);

    const validationSchema = yup.object({
        // merchantId: yup.object().required("Required Field").nullable(),
        account_holder: yup.string().required('Required Field').nullable(),
        account_number: yup.number().required('Required Field').nullable(),
        ifsc_code: yup.string().trim().required('Required Field').matches('^[A-Za-z]{4}[a-zA-Z0-9]{7}$', 'Invalid IFSC'),
        customer_id: yup.string().required('Required Field').nullable(),
        payout_amount: yup.number().required('Required Field').nullable(),
        ref_id: yup.string().required('Required Field').nullable()
    });

    const formik = useFormik({
        validateOnChange: true,
        validateOnBlur: true,
        enableReinitialize: true,
        initialValues: details,
        validationSchema,
        onSubmit: (data, { resetForm }) => {
            setLoading(true);
            axiosServices
                .post('single/create/payout', { ...data, merchantId: user?.merchantId })
                .then((res) => {
                    setLoading(false);
                    formik.resetForm();
                    setDetails({});
                    handleCloseDialog();
                    dispatch(
                        openSnackbar({
                            open: true,
                            message: res?.data?.message || 'Saved Successfully',
                            variant: 'alert',
                            alert: {
                                color: 'success'
                            },
                            close: false
                        })
                    );
                })
                .catch((e) => {
                    setLoading(false);
                    dispatch(
                        openSnackbar({
                            open: true,
                            message: e?.message || 'Error while adding details',
                            variant: 'alert',
                            alert: {
                                color: 'success'
                            },
                            close: false
                        })
                    );
                });
        }
    });

    return (
        <Dialog open={open} TransitionComponent={Transition} fullWidth="true" maxWidth="xs" keepMounted>
            {open && (
                <form onSubmit={formik.handleSubmit}>
                    <DialogTitle>Create Payout Txn</DialogTitle>
                    <DialogContent>
                        {loading && <Loader />}

                        <Grid container spacing={1} sx={{ mt: 0.25 }}>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    id="ifsc_code"
                                    name="ifsc_code"
                                    sx={{ mb: 2 }}
                                    label="IFSC Code"
                                    value={formik.values.ifsc_code || null}
                                    onChange={formik.handleChange}
                                    error={Boolean(formik.errors.ifsc_code)}
                                    helperText={formik.errors.ifsc_code}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    id="account_holder"
                                    sx={{ mb: 2 }}
                                    name="account_holder"
                                    label="Account Holder"
                                    value={formik.values.account_holder || null}
                                    onChange={formik.handleChange}
                                    error={Boolean(formik.errors.account_holder)}
                                    helperText={formik.errors.account_holder}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    id="account_number"
                                    sx={{ mb: 2 }}
                                    name="account_number"
                                    label="Account Number"
                                    value={formik.values.account_number || null}
                                    onChange={formik.handleChange}
                                    error={Boolean(formik.errors.account_number)}
                                    helperText={formik.errors.account_number}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    id="customer_id"
                                    name="customer_id"
                                    sx={{ mb: 2 }}
                                    label="Customer ID"
                                    value={formik.values.customer_id || null}
                                    onChange={formik.handleChange}
                                    error={Boolean(formik.errors.customer_id)}
                                    helperText={formik.errors.customer_id}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    id="payout_amount"
                                    name="payout_amount"
                                    label="Payout Amount"
                                    sx={{ mb: 2 }}
                                    value={formik.values.payout_amount || null}
                                    onChange={formik.handleChange}
                                    error={Boolean(formik.errors.payout_amount)}
                                    helperText={formik.errors.payout_amount}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    id="ref_id"
                                    name="ref_id"
                                    label="Ref ID"
                                    sx={{ mb: 2 }}
                                    value={formik.values.ref_id || null}
                                    onChange={formik.handleChange}
                                    error={Boolean(formik.errors.ref_id)}
                                    helperText={formik.errors.ref_id}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <AnimateButton>
                            <Button disabled={loading} type="submit" variant="contained">
                                Save
                            </Button>
                        </AnimateButton>
                        <Button
                            variant="text"
                            color="error"
                            disabled={loading}
                            onClick={() => {
                                formik.resetForm();
                                setDetails({});
                                handleCloseDialog();
                            }}
                        >
                            Close
                        </Button>
                    </DialogActions>
                </form>
            )}
        </Dialog>
    );
};

Add.propTypes = {
    open: PropTypes.bool,
    handleCloseDialog: PropTypes.func
};

export default Add;
