const LAYOUT_CONST = {
    VERTICAL_LAYOUT: 'vertical',
    HORIZONTAL_LAYOUT: 'horizontal',
    DEFAULT_DRAWER: 'default',
    MINI_DRAWER: 'mini-drawer'
};

export default LAYOUT_CONST;

export const MOCK_DATA = {
    token: 'eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJUZXN0VXNlciIsInVzZXJJZCI6ImE2MWE3ZWM4LTBhODAtNDg3ZC04MDQyLTVlN2VhOGZlZGRkMCIsImVtYWlsIjoidGVzdHBheUBwYXkuY29tIiwicm9sZSI6IlJPTEVfU1VQRVJBRE1JTiIsImlhdCI6MTY4NjY3NzQ5MiwiZXhwIjoxNjg2NzM3NDkyfQ.3Ej4l6G5N6YQ85P495QBj9VSBgQNeSrVFyK1mwvg5c7hk2fSCvklLmLzLN7A_t0Gos71e1lBynvtcb3xqSmXFQ',
    type: 'Bearer',
    refreshToken: 'c9fb6589-1ee3-40f4-b19d-34e45a75b48d',
    id: 'a61a7ec8-0a80-487d-8042-5e7ea8feddd0',
    username: 'TestUser',
    email: 'testpay@pay.com',
    role: 'ROLE_SUPERADMIN'
};
export const ROLE_SUPERADMIN = 'ROLE_SUPERADMIN';
export const ROLE_ADMIN = 'ROLE_ADMIN';
export const ROLE_AGENT = 'ROLE_AGENT';
export const ROLE_MERCHANT = 'ROLE_MERCHANT';

export const AVAILABLE_METHODS = ['UPI', 'IMPS', 'UPI,IMPS'];

// Get the current date
const currentDate = new Date();

// Create options for formatting the date
const options = {
    timeZone: 'Asia/Kolkata', // 'Asia/Kolkata' is the time zone for IST
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
};

// Format the date in IST
const date = currentDate.toLocaleString('en-IN', options).split('/');

export const ISTDate = `${date[2]}-${date[1]}-${date[0]}`;
