// material-ui
import { useTheme } from '@mui/material/styles';
import { Button, Grid, TextField } from '@mui/material';

// project imports
import AnimateButton from 'ui-component/extended/AnimateButton';
import { gridSpacing } from 'store/constant';
import { useFormik } from 'formik';
import { closeSnackbar, openSnackbar } from 'store/slices/snackbar';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import axiosServices from 'utils/axios';

const validationSchema = yup.object({
    currentPassword: yup.string().required('Required Field'),
    newPassword: yup.string().required('Required Field').min(6),
    confirmNewPassword: yup.string().required('Required Field').min(6)
});

// ==============================|| PROFILE 1 - CHANGE PASSWORD ||============================== //

const ChangePassword = ({ onClose, onSubmit }) => {
    const theme = useTheme();
    const dispatch = useDispatch();

    const formik = useFormik({
        validateOnChange: true,
        validateOnBlur: true,
        enableReinitialize: true,
        initialValues: {},
        validationSchema,
        onSubmit: (data, { resetForm }) => {
            axiosServices
                .post('changePassword', data)
                .then((res) => {
                    resetForm();
                    dispatch(
                        openSnackbar({
                            open: true,
                            message: 'Password changed!',
                            variant: 'alert',
                            alert: {
                                color: 'success'
                            },
                            close: false
                        })
                    );
                    onSubmit();
                })
                .catch((e) => {
                    dispatch(
                        openSnackbar({
                            open: true,
                            message: e?.message || 'Error while changing password',
                            variant: 'alert',
                            alert: {
                                color: 'error'
                            },
                            close: true
                        })
                    );
                    dispatch(closeSnackbar);
                });
        }
    });

    return (
        <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>
                <form onSubmit={formik.handleSubmit}>
                    <Grid container spacing={gridSpacing} sx={{ mb: 4 }}>
                        <Grid item xs={12} md={6}>
                            <TextField
                                value={formik.values.currentPassword}
                                onChange={formik.handleChange}
                                error={Boolean(formik.errors.currentPassword)}
                                helperText={formik.errors.currentPassword}
                                type="password"
                                id="currentPassword"
                                fullWidth
                                label="Current Password"
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={gridSpacing} sx={{ mb: 4 }}>
                        <Grid item xs={12} md={6}>
                            <TextField
                                value={formik.values.newPassword}
                                onChange={formik.handleChange}
                                error={Boolean(formik.errors.newPassword)}
                                helperText={formik.errors.newPassword}
                                type="password"
                                id="newPassword"
                                fullWidth
                                label="New Password"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                value={formik.values.confirmNewPassword}
                                onChange={formik.handleChange}
                                error={Boolean(formik.errors.confirmNewPassword)}
                                helperText={formik.errors.confirmNewPassword}
                                type="password"
                                id="confirmNewPassword"
                                fullWidth
                                label="Confirm Password"
                            />
                        </Grid>
                    </Grid>
                    <Grid spacing={2} container justifyContent="flex-end" sx={{ mt: 3 }}>
                        <Grid item>
                            <AnimateButton>
                                <Button
                                    disabled={
                                        !formik.values.currentPassword || !formik.values.newPassword || !formik.values.confirmNewPassword
                                    }
                                    type="submit"
                                    variant="contained"
                                >
                                    Change Password
                                </Button>
                            </AnimateButton>
                        </Grid>
                        <Grid item>
                            <Button
                                onClick={() => {
                                    formik.resetForm();
                                    formik.setFieldValue('currentPassword', '');
                                    formik.setFieldValue('newPassword', '');
                                    formik.setFieldValue('confirmNewPassword', '');
                                    onClose();
                                }}
                                sx={{ color: theme.palette.error.main }}
                            >
                                Close
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Grid>
        </Grid>
    );
};

export default ChangePassword;
