// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconGitPullRequest, IconHelp, IconSitemap, IconReport, IconSettings } from '@tabler/icons';

// constant
const icons = {
    IconReport,
    IconHelp,
    IconSitemap,
    IconSettings,
    IconGitPullRequest
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

export const MerchantOther = {
    id: 'others',
    title: <FormattedMessage id="Other" />,
    type: 'group',
    children: [
        {
            id: 'Reports',
            title: <FormattedMessage id="Reports" />,
            type: 'item',
            url: '/other/reports',
            icon: icons.IconReport,
            breadcrumbs: false
        },

        // {
        //     id: 'settings',
        //     title: <FormattedMessage id="Settings" />,
        //     type: 'item',
        //     url: '/other/settings',
        //     icon: icons.IconSettings,
        //     breadcrumbs: false
        // },
        {
            id: 'request',
            title: <FormattedMessage id="Request" />,
            type: 'collapse',
            icon: icons.IconGitPullRequest,
            children: [
                {
                    id: 'payout',
                    title: <FormattedMessage id="Payout" />,
                    type: 'item',
                    url: '/other/payout-request',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'support',
            title: <FormattedMessage id="Support" />,
            type: 'item',
            url: '/other/support',
            icon: icons.IconHelp,
            breadcrumbs: false
        }
    ]
};
