import React, { useEffect, useState } from 'react';

// material-ui
import { Button, Grid, CardContent } from '@mui/material';

// project imports
import MainCard from 'ui-component/cards/MainCard';

// assets
import Loader from 'ui-component/Loader';
import Add from './Add';
import { useDispatch } from 'react-redux';
import { openSnackbar } from 'store/slices/snackbar';

const CreatePayout = () => {
    const dispatch = useDispatch();

    const [loading, setLoading] = React.useState(false);
    const [copy, setCopy] = React.useState(false);

    const [open, setOpen] = React.useState(false);

    React.useEffect(() => {
        if (copy) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Copied!',
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: false
                })
            );
            setCopy(false);
        }
    }, [copy]);

    const handleCloseDialog = () => {
        setOpen(false);
    };

    return (
        <MainCard
            title={
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>Payout Transaction</div>
                </div>
            }
        >
            {loading && <Loader />}
            <CardContent>
                <Grid container alignItems="center" spacing={1}>
                    {/* <Grid item>
                        <Autocomplete
                            id="merchant-select"
                            options={merchants || []}
                            onChange={(e, value) => {
                                setSelectedMerchant(value);
                            }}
                            sx={{ width: 200 }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    size="small"
                                    onChange={(e, value) => {
                                        setSelectedMerchant(value);
                                    }}
                                    label="Merchants"
                                />
                            )}
                        />
                    </Grid> */}
                    <Grid item>
                        <Button style={{ marginLeft: 20 }} size="small" variant="contained" onClick={() => setOpen(true)}>
                            Create Payout Transaction
                        </Button>
                    </Grid>
                </Grid>
                <Add open={open} handleCloseDialog={handleCloseDialog} />
            </CardContent>
        </MainCard>
    );
};

export default CreatePayout;
