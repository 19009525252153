import React, { useEffect, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import { CardContent, Dialog, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

const Support = () => {
    const [open, setOpen] = useState(false);
    return (
        <MainCard
            title={
                <div style={{ fontWeight: 500, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div>Support</div>

                    <div
                        // eslint-disable-line jsx-a11y/no-static-element-interactions
                        onKeyDown={() => {}}
                        role="presentation"
                        onClick={() => setOpen(true)}
                        style={{ fontSize: 14, cursor: 'pointer', color: 'blue' }}
                    >
                        How it works?
                    </div>
                </div>
            }
        >
            <CardContent>
                <div style={{ fontWeight: 'bold', marginBottom: 5 }}>1. Transaction Pending State Duration:</div>

                <div>
                    When a transaction is in a pending state, it is typically expected to be finalized within a window of 40 to 90 seconds.
                    This brief timeframe allows for the necessary checks and confirmations to take place. However, if incorrect information
                    is provided by the user, whether it pertains to the transaction amount or the Unique Transaction Reference (UTR), the
                    transaction may encounter a pending state as a result.
                </div>
                <div style={{ fontWeight: 'bold', marginTop: 20, marginBottom: 5 }}>2. Initial Transaction State:</div>

                <div>
                    If a user proceeds to the checkout page without making any selections related to payment methods such as UPI or IMPS,
                    the transaction will assume an initial state. During this phase, the user has yet to specify the payment method they
                    intend to use.
                </div>

                <div style={{ fontWeight: 'bold', marginTop: 20, marginBottom: 5 }}>3. Processing Transaction State:</div>
                <div>
                    Upon reaching the checkout page and choosing options like UPI or IMPS for payment, the transaction transitions into a
                    processing state. This stage indicates that the payment process has been initiated and is currently being handled.
                </div>

                <div style={{ fontWeight: 'bold', marginTop: 20, marginBottom: 5 }}>4. Pending Transaction State:</div>

                <div>
                    When a user navigates to the checkout page, selects payment options like UPI or IMPS, and then submits their transaction
                    with the UTR (Unique Transaction Reference) provided, the transaction enters a pending state. During this interval, the
                    system awaits confirmation or validation of the submitted details before proceeding further.
                </div>

                <div style={{ fontWeight: 'bold', marginTop: 20 }}>
                    Feel free to reach out if you need any more assistance or clarifications!
                </div>
            </CardContent>

            <Dialog
                open={open}
                keepMounted
                onClose={() => setOpen(false)}
                aria-labelledby="alert-dialog-slide-title1"
                aria-describedby="alert-dialog-slide-description1"
            >
                {open && (
                    <>
                        <DialogTitle id="alert-dialog-slide-title1">How it works</DialogTitle>
                        <DialogContent>
                            <iframe
                                allowFullScreen
                                title="Merchant Panel"
                                width="420"
                                height="315"
                                src="https://www.youtube.com/embed/u5A34jJoMQA"
                            />
                        </DialogContent>
                    </>
                )}
            </Dialog>
        </MainCard>
    );
};

export default Support;
