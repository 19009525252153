import { useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Button, Divider, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';

// project imports
import SubCard from 'ui-component/cards/SubCard';
import Chip from 'ui-component/extended/Chip';
import Logo from 'ui-component/Logo';
import { gridSpacing } from 'store/constant';
import AnimateButton from 'ui-component/extended/AnimateButton';
import ReactToPrint from 'react-to-print';
import axiosServices from 'utils/axios';
import Loader from 'ui-component/Loader';

// table data
function createData(product, description, quantity, amount, total) {
    return { product, description, quantity, amount, total };
}

const Invoice = () => {
    const theme = useTheme();
    const componentRef = useRef(null);

    const { settlementId, merchantId } = useParams();

    const [loading, setLoading] = useState(false);

    const [companyDetails, setCompanyDetails] = useState();
    const [merchantDetails, setMerchantDetails] = useState();
    const [settlementDetails, setSettlementDetails] = useState();

    useEffect(() => {
        if (settlementId && merchantId) {
            setLoading(true);
            axiosServices
                .get(`settle/invoice/${settlementId}/${merchantId}`)
                .then((res) => {
                    setLoading(false);
                    setCompanyDetails(res.data?.data?.companyDetails);
                    setMerchantDetails(res.data?.data?.merchantDetails);
                    setSettlementDetails(res.data?.data?.settlementDetails);
                })
                .catch((e) => {
                    setLoading(false);
                    alert(e.message);
                });
        }
    }, [settlementId, merchantId]);

    if (loading) return <Loader />;

    const fee1 = (settlementDetails?.conversionRate * settlementDetails?.conversionFee) / 100;

    const fee = parseFloat(settlementDetails?.conversionRate) + parseFloat(fee1);

    return (
        <Grid container justifyContent="center" spacing={gridSpacing}>
            <Grid item xs={12} md={10} lg={8}>
                <SubCard darkTitle ref={componentRef} title={`Invoice #${settlementDetails?.invoiceNumber}`} secondary={<Logo />}>
                    <Grid container spacing={gridSpacing}>
                        <Grid item xs={12}>
                            <Grid container spacing={0}>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle1">{companyDetails?.name}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body2">{companyDetails?.address.split(',')[0]}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body2">{companyDetails?.address.split(',')[1]}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body2">{companyDetails?.email}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href={
                                            companyDetails?.website?.includes('http')
                                                ? companyDetails?.website
                                                : `http://${companyDetails?.website}`
                                        }
                                    >
                                        {companyDetails?.website}
                                    </a>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container justifyContent="space-between" spacing={gridSpacing}>
                                <Grid item sm={5}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography variant="h5">To</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container spacing={0}>
                                                <Grid item xs={12}>
                                                    <Typography variant="subtitle1">{merchantDetails?.merchantName}</Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant="body2">{merchantDetails?.merchantId}</Typography>
                                                </Grid>
                                                <Typography variant="body2">{merchantDetails?.merchantEmail}</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant="body2">{merchantDetails?.merchantPhone}</Typography>
                                            </Grid>
                                            {merchantDetails?.merchantBrandUrl && (
                                                <Grid item xs={12}>
                                                    <Typography
                                                        component={Link}
                                                        to={merchantDetails?.merchantBrandUrl}
                                                        variant="body2"
                                                        color="primary"
                                                    >
                                                        {merchantDetails?.merchantBrandUrl}
                                                    </Typography>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item sm={4}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography variant="h5">Order Details :</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container spacing={0}>
                                                <Grid item xs={4}>
                                                    <Typography variant="body2">Date :</Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <Typography variant="body2">
                                                        {new Date(settlementDetails?.date).toLocaleString()}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={4} sx={{ my: 0.5 }}>
                                                    <Typography variant="body2">Status :</Typography>
                                                </Grid>
                                                <Grid item xs={8} sx={{ my: 0.5 }}>
                                                    <Chip
                                                        label={settlementDetails?.status}
                                                        variant="outlined"
                                                        size="small"
                                                        chipcolor="success"
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <TableContainer>
                                <Table
                                    sx={{
                                        '& tr:last-of-type td': {
                                            borderBottom: 'none'
                                        },
                                        '& thead tr th': {
                                            borderBottom: 'none'
                                        },
                                        '& th:first-of-type, & td:first-of-type': {
                                            pl: { xs: 2.5, md: 5 }
                                        },
                                        '& th:last-of-type, & td:last-of-type': {
                                            pr: { xs: 6.25, md: 8.75 }
                                        }
                                    }}
                                >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{ pl: 3 }}>Remark</TableCell>
                                            <TableCell sx={{ pl: 3 }}>Type</TableCell>
                                            <TableCell align="right" sx={{ pr: 3 }}>
                                                TOTAL
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell sx={{ pl: 3 }}>
                                                <Typography align="left" variant="subtitle1">
                                                    {settlementDetails?.remark}
                                                    <div>
                                                        Ref No:
                                                        {settlementDetails?.conversionType === 'INR' ? (
                                                            <Typography align="left" variant="body2">
                                                                {settlementDetails?.refNum}
                                                            </Typography>
                                                        ) : (
                                                            <a
                                                                title={settlementDetails?.refNum}
                                                                rel="noreferrer"
                                                                target="_blank"
                                                                href={settlementDetails?.refNum}
                                                            >
                                                                {settlementDetails?.refNum?.slice(0, 50)}
                                                            </a>
                                                        )}
                                                    </div>
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                {settlementDetails?.conversionType}({settlementDetails?.conversionRate})
                                            </TableCell>
                                            <TableCell align="right" sx={{ pr: 3 }}>
                                                Rs.{settlementDetails?.settlementAmount}
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                        <Grid item xs={12}>
                            <SubCard
                                sx={{
                                    bgcolor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light
                                }}
                            >
                                <Grid container justifyContent="flex-end" spacing={gridSpacing}>
                                    <Grid item sm={6} md={4}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={6}>
                                                        <Typography align="right" variant="subtitle1">
                                                            Sub Total :
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography align="right" variant="body2">
                                                            Rs. {settlementDetails?.settlementAmount}
                                                        </Typography>
                                                    </Grid>
                                                    {settlementDetails?.conversionType === 'INR' ? (
                                                        <>
                                                            <Grid item xs={6}>
                                                                <Typography align="right" variant="subtitle1">
                                                                    Fees ({settlementDetails?.conversionFee}%) :
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <Typography align="right" variant="body2">
                                                                    - Rs. {settlementDetails?.percentageAmount}
                                                                </Typography>
                                                            </Grid>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <Grid item xs={6}>
                                                                <Typography align="right" variant="subtitle1">
                                                                    Wazir + {settlementDetails?.conversionFee}%:
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <Typography align="right" variant="body2">
                                                                    {fee?.toFixed(2) || ''}
                                                                </Typography>
                                                            </Grid>
                                                        </>
                                                    )}
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Divider />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={6}>
                                                        <Typography align="right" color="primary" variant="subtitle1">
                                                            Total :
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography align="right" color="primary" variant="subtitle1">
                                                            {settlementDetails?.grandTotal}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </SubCard>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="h6">Terms and Condition :</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body2">Terms and Condition</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </SubCard>
            </Grid>
            <Grid item xs={12} md={10} lg={8}>
                <Grid
                    container
                    spacing={1}
                    justifyContent="center"
                    sx={{
                        maxWidth: 850,
                        mx: 'auto',
                        mt: 0,
                        mb: 2.5,
                        '& > .MuiCardContent-root': {
                            py: { xs: 3.75, md: 5.5 },
                            px: { xs: 2.5, md: 5 }
                        }
                    }}
                >
                    <Grid item>
                        <AnimateButton>
                            <ReactToPrint trigger={() => <Button variant="contained">Print</Button>} content={() => componentRef.current} />
                        </AnimateButton>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Invoice;
