import { TableRow, TableHead, TableCell } from '@mui/material';

function TableHeader({ columns }) {
    return (
        <TableHead>
            <TableRow>
                {columns.map((headCell) => (
                    <TableCell key={headCell.id} align={headCell.align} padding={headCell.disablePadding ? 'none' : 'normal'}>
                        {headCell.label}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

export default TableHeader;
