// third-party
import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
    error: null
};

const slice = createSlice({
    name: 'cart',
    initialState,
    reducers: {}
});

// Reducer
export default slice.reducer;
